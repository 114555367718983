.highlight{
    font-size: 4.5rem;
    font-family:Verdana, Geneva, Tahoma, sans-serif ;
    
}

.imgStyle {
    filter: "blur(1px)";
    height: "90.8vh";
    background-position: "left left";
  }
  

@media(max-width:768px){
    .highlight{
        font-size: 3rem;
    }

    .imgStyle {
        filter: "blur(1px)";
        height: "78.8vh";
        background-position: "left left";
      }
      
}