.post{
  width:420px;
  margin-top: 10px;
  margin-right: 25px;
  margin-bottom: 40px;
  margin-left: 25px;
  border: 1px solid;
  padding: 15px;
  border-radius: 15px;
}
.postImg{
  width: 100%;
  height: 280px;
  object-fit: cover;
  border-radius: 10px;
}
.postInfo{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.postCats{
  padding-top: 25px;
}
.postCat{

  font-family: "Varela Round" , sans-serif;
  font-size: 16px;
  color:#be9656;
  margin-top: 20px;
  line-height: 20px;
  margin-right: 15px;
  cursor: pointer;
  font-weight: 800;
}
.postTitle{

  font-family: 'Josefin Sans' , sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin-top: 15px;
  cursor: pointer;
}
.postDate{
  font-family: 'Lora' , serif;
  font-style: italic;
  color: #999 ;
  /* margin-top: 15px; */
}
.postDesc{
  font-family: 'Varela Roound' , sans-serif;
  font-size: 15px;
  color: #444;
  margin-top: 5px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
hr.roll{
  margin: 0px !important;
  width: 4px;
  height: 50px;
}
@media all and (max-width:430px) {
  .posts{
    width: 100%;
    margin-left: 0px;
  }
}