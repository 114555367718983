@import url(https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* ./src/index.css */

html{
  background-color: #1e1e1e;
}
/* @import url('https://fonts.googleapis.com/css2?family=Anton&display=swap'); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

.nav-head{
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-logo {
  height: 5rem;
}

.navbar-brand {
  font-size: 1.5rem !important;
  font-weight: bolder;
  color: rgb(139,120,6) !important;
  border-bottom: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.navbar-brand:hover {
  border-bottom: none !important;
  color: rgb(160, 172, 168);
  /* text-decoration: none; */
}

.navbar a {
  font-size: 1 rem;
  text-transform: capitalize;
  font-weight: 600;
}

.navbar ul li {
  padding: 5px;
  /* margin-right: 38px; */
}

.menu_active {
  font-weight: bold;
}

.navbar a:hover {
  color: rgb(139,120,6) !important;
  font-weight: bolder;
  border-bottom: 0.2rem solid rgb(139,120,6);
  border-bottom-width: thin;
}

.menu_active {
  font-weight: bold;
  border-bottom: 0.2rem solid#000;
  color: #000 !important;
}
@media all and (max-width: 1024px) {
  .navbar ul li{
    padding: 2px;
  }
}
@media all and (max-width: 990px) {
  .navbar-brand {
    font-size: 2rem !important;
    border-bottom: none;
  }
  .logo1{
    margin-left: -80px !important;
  }
  .title1{
    margin-right: 27%!important;
  }
  button.navbar-toggler{
    margin-left: 99%;
    margin-top: -68px;
  }
}
@media all and (max-width: 768px) {
  .navbar-brand {
    font-size: 2rem !important;
    border-bottom: none;
  }
  .logo1{
    margin-left: -80px !important;
  }
  .title1{
    margin-right: 27%!important;
    padding-top: 20px !important;
  }
  button.navbar-toggler{
    margin-left: 99%;
    margin-top: -68px;
  }
}
@media all and (max-width: 580px) {
  .navbar-brand {
    font-size: 2rem !important;
    border-bottom: none;
  }
  .logo1{
    margin-left: -80px !important;
  }
  .title1{
    margin-right: 18%!important;
  }
  button.navbar-toggler{
    margin-left: 99%;
    margin-top: -68px;
  }
}
@media all and (max-width: 498px) {
  .navbar-brand {
    font-size: 2rem !important;
    border-bottom: none;
  }
  .logo1{
    margin-left: -80px !important;
  }
  .title1{
    margin-right: 15%!important;
  }
  button.navbar-toggler{
    margin-left: 99%;
    margin-top: -68px;
  }
}
@media all and (max-width: 470px) {
  .navbar-brand {
    font-size: 2rem !important;
    border-bottom: none;
  }
  .logo1{
    margin-left: -70px !important;
  }
  .title1{
    margin-right: 25%!important;
    font-size: 20px !important;
  }
  button.navbar-toggler{
    margin-left: 99%;
    margin-top: -68px;
  }
}
/* @media all and (max-width: 430px){
  .logo1{
    margin-left: -28% !important;
  }
  .title1{
    margin-left: 19%;
    font-size: 20px!important;
    margin-top: -44%;
  }
  button.navbar-toggler{
    margin-left: 93%;
    margin-top: -44%;
  }
} */
@media all and (max-width: 400px){
  .logo1{
    margin-left: -28% !important;
  }
  .title1{
    margin-left: 15%;
    /* font-size: 20px!important; */
    margin-top: -35%;
  }
  button.navbar-toggler{
    margin-left: 93%;
    margin-top: -28%;
  }
  #navbarSupportedContent{
    margin-left: 15px;
  }
}
@media all and (max-width: 360px){
  .logo1{
    margin-left: -30% !important;
  }
  .title1{
    margin-left: 15%;
    font-size: 18px!important;
    /* margin-top: -40%; */
  }
  button.navbar-toggler{
    margin-left: 93%;
    margin-top: -30%;
  }
}

@media all and (max-width: 320px){
  .logo1{
    margin-left: -37% !important;
  }
  .title1{
    margin-left: 14%;
    font-size: 18px!important;
    /* margin-top: -48%; */
  }
  button.navbar-toggler{
    margin-left: 93%;
    margin-top: -38%;
  }
}

.App {
  text-align: center;
  background-color: rgb(241, 239, 239);
  color:#000;
  overflow-x: hidden;
}
.carousel-indicators li {
  text-decoration: none;
  background-color: darkgray;
}

h1{
  font-size: 2.8rem !important;
  color: rgb(139, 120, 9) !important;
  font-weight: 900;
  font-family:Verdana, Geneva, Tahoma, sans-serif ;
}

b{
  font-weight: bolder;
}

h2{
  font-size: 2.3rem !important;
  color: rgb(139, 120, 9) !important;
  font-family:Verdana, Geneva, Tahoma, sans-serif ;
}

h3{
  font-size: 2.0rem !important  ;
  color: rgb(139, 120, 9) !important;
  font-family:Verdana, Geneva, Tahoma, sans-serif ;
}

h4{
  font-size: 2.2rem ;
  color: rgb(139, 120, 9);
  font-weight: bolder;
  font-family:Verdana, Geneva, Tahoma, sans-serif ;
}

h5{
  font-size: 2.0rem  ;
  color: rgb(139, 120, 9);
  font-family:Verdana, Geneva, Tahoma, sans-serif ;
}

h6{
  font-size: 1.5rem ;
  font-family:Verdana, Geneva, Tahoma, sans-serif ;
}

p{
  font-size: 16px;
  font-family: sans-serif;
}

@media(max-width:768px){

  h1{
    font-size: 2.5rem !important;
  }
  
  h2{
    font-size: 2rem !important;
  }

  h3{
    font-size: 1.8rem !important;
  }
  
  p{
    font-size: 15px;
  }
}
.card_card-container__1-d9I {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* padding: 5px 20px; */
  justify-items: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  grid-auto-flow: row;
}

.card_card__2Uoui {
  border: 1px solid black;
  /* border-radius: 10px; */
  margin: 5px 5px;
  color: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  height: 32rem;
  width: 25rem;
  margin-bottom: 80px;
  position: relative;
}

.card_card__2Uoui span {
  text-shadow: -1px 1px 0 rgb(139, 120, 9), 1px 1px 0 rgb(139, 120, 9),
    1px -1px 0 rgb(139, 120, 9), -1px -1px 0 rgb(139, 120, 9);
  color: #fff;
  transition: all ease-in 0.5s;
}

.card_card__2Uoui:hover span {
  color: rgb(139, 120, 9);
}

.card_top__2PY-b {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  background-color: rgb(139, 120, 9);
  height: 5px;
  transition: all 0.5s ease-in-out;
  -webkit-animation: card_topexit__3wDrt 0.5s ease-in-out forwards;
          animation: card_topexit__3wDrt 0.5s ease-in-out forwards;
}

.card_card__2Uoui:hover .card_top__2PY-b {
  transition: all 0.5s ease-in-out;
  -webkit-animation: card_topentry__1hPEm 0.5s ease-in-out forwards;
          animation: card_topentry__1hPEm 0.5s ease-in-out forwards;
}

@-webkit-keyframes card_topentry__1hPEm {
  from {
    transition: all 0.5s ease-in-out;
    width: 0;
  }
  to {
    transition: all 0.5s ease-in-out;
    width: 100%;
  }
}

@keyframes card_topentry__1hPEm {
  from {
    transition: all 0.5s ease-in-out;
    width: 0;
  }
  to {
    transition: all 0.5s ease-in-out;
    width: 100%;
  }
}

@-webkit-keyframes card_topexit__3wDrt {
  from {
    transition: all 0.5s ease-in-out;
    width: 100%;
  }
  to {
    transition: all 0.5s ease-in-out;
    width: 0;
  }
}

@keyframes card_topexit__3wDrt {
  from {
    transition: all 0.5s ease-in-out;
    width: 100%;
  }
  to {
    transition: all 0.5s ease-in-out;
    width: 0;
  }
}

.card_left__3i4f2 {
  position: absolute;
  left: 0;
  width: 5px;
  background-color: rgb(139, 120, 9);
  height: 0;
  -webkit-animation: card_leftexit__151qQ 0.5s ease-in-out forwards;
          animation: card_leftexit__151qQ 0.5s ease-in-out forwards;
}

.card_card__2Uoui:hover .card_left__3i4f2 {
  transition: all 0.5s ease-in-out;
  -webkit-animation: card_leftentry__3NN1V 0.5s ease-in-out forwards;
          animation: card_leftentry__3NN1V 0.5s ease-in-out forwards;
}

@-webkit-keyframes card_leftentry__3NN1V {
  from {
    transition: all 0.5s ease-in-out;
    height: 0;
  }
  to {
    transition: all 0.5s ease-in-out;
    height: 100%;
  }
}

@keyframes card_leftentry__3NN1V {
  from {
    transition: all 0.5s ease-in-out;
    height: 0;
  }
  to {
    transition: all 0.5s ease-in-out;
    height: 100%;
  }
}

@-webkit-keyframes card_leftexit__151qQ {
  from {
    transition: all 0.5s ease-in-out;
    height: 100%;
  }
  to {
    transition: all 0.5s ease-in-out;
    height: 0;
  }
}

@keyframes card_leftexit__151qQ {
  from {
    transition: all 0.5s ease-in-out;
    height: 100%;
  }
  to {
    transition: all 0.5s ease-in-out;
    height: 0;
  }
}

.card_right__CwRv7 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 5px;
  background-color: rgb(139, 120, 9);
  height: 0;
  -webkit-animation: card_rightexit__4vUf0 0.5s ease-in-out forwards;
          animation: card_rightexit__4vUf0 0.5s ease-in-out forwards;
}

.card_card__2Uoui:hover .card_right__CwRv7 {
  transition: all 0.5s ease-in-out;
  -webkit-animation: card_rightentry__2Gq8f 0.5s ease-in-out forwards;
          animation: card_rightentry__2Gq8f 0.5s ease-in-out forwards;
}

@-webkit-keyframes card_rightentry__2Gq8f {
  from {
    transition: all 0.5s ease-in-out;
    height: 0;
  }
  to {
    transition: all 0.5s ease-in-out;
    height: 100%;
  }
}

@keyframes card_rightentry__2Gq8f {
  from {
    transition: all 0.5s ease-in-out;
    height: 0;
  }
  to {
    transition: all 0.5s ease-in-out;
    height: 100%;
  }
}

@-webkit-keyframes card_rightexit__4vUf0 {
  from {
    transition: all 0.5s ease-in-out;
    height: 100%;
  }
  to {
    transition: all 0.5s ease-in-out;
    height: 0;
  }
}

@keyframes card_rightexit__4vUf0 {
  from {
    transition: all 0.5s ease-in-out;
    height: 100%;
  }
  to {
    transition: all 0.5s ease-in-out;
    height: 0;
  }
}

.card_bottom__2jI1t {
  position: absolute;
  bottom: 0;
  width: 0;
  background-color: rgb(139, 120, 9);
  height: 5px;
  -webkit-animation: card_bottomexit__3-FbD 0.5s ease-in-out forwards;
          animation: card_bottomexit__3-FbD 0.5s ease-in-out forwards;
}

.card_card__2Uoui:hover .card_bottom__2jI1t {
  transition: all 0.5s ease-in-out;
  -webkit-animation: card_bottomentry__r3MP6 0.5s ease-in-out forwards;
          animation: card_bottomentry__r3MP6 0.5s ease-in-out forwards;
}

@-webkit-keyframes card_bottomentry__r3MP6 {
  from {
    transition: all 0.5s ease-in-out;
    width: 0;
  }
  to {
    transition: all 0.5s ease-in-out;
    width: 100%;
  }
}

@keyframes card_bottomentry__r3MP6 {
  from {
    transition: all 0.5s ease-in-out;
    width: 0;
  }
  to {
    transition: all 0.5s ease-in-out;
    width: 100%;
  }
}

@-webkit-keyframes card_bottomexit__3-FbD {
  from {
    transition: all 0.5s ease-in-out;
    width: 100%;
  }
  to {
    transition: all 0.5s ease-in-out;
    width: 0;
  }
}

@keyframes card_bottomexit__3-FbD {
  from {
    transition: all 0.5s ease-in-out;
    width: 100%;
  }
  to {
    transition: all 0.5s ease-in-out;
    width: 0;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .card_card-container__1-d9I {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .card_card-container__1-d9I {
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-content: center;
  }

  .card_card__2Uoui {
    width: 18rem;
    height: 30rem;
  }
}

a{
  text-decoration: none;
  color: rgb(139,120,9) !important;
}

a:hover{
  color:rgb(139,120,9);
}
.campaign_wrapper__2iItP{
  padding: 5px 10px;
}

.campaign_heading___tbw1 {
  text-align: center;
  font-family: "Roboto" serif;
  font-size: 2rem;
  z-index: 2;
  margin: 40px 50px;
  color:rgb(139,120,9) !important;
}

.campaign_content__vFWgU {
  text-align: center;
  color:#000;
  line-height: 1.5rem;
  font-weight: normal;
  font-size: 1rem;
}

.campaign_img-background__2CYSf{
  width:100%;
  background-color: rgba(250,250,250,0.8);
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaign_img-background__2CYSf .campaign_imageContent__10uuP{
  height: 150px;
  width:150px;
  border-radius: 50%;
  margin-top: 50px;
}

@media(max-width:768px) {
  .campaign_imageContent__10uuP{
    height: 150px;
    width: 150px;
    font-size: 1rem;
    /* margin-bottom: 12rem; */
  }

  .campaign_content__vFWgU{
    line-height:1rem;
    font-size: 14px;
    margin-bottom: 30px;
  }

  .campaign_wrapper__2iItP{
    padding: 0 10px;
  }
}
.about_section__2DlWI{
    height:80vh;
    width: 100%;
}

.about_wrapper__1Xnwt{
    display: flex;
}

.about_image__lCi-R{
    background-color: #fff;
    width:50%;
}

.about_content__3u5BZ{
    width: 50%;
    background-color: #ddd;
}

.about_content-inner__32un_{
    border: 1px solid #fff;
    margin-left: -50px;
    margin-top: 50px;
    background-color: #fff;
}

.about_content__3u5BZ p{
    color: #000;
    margin: 50px;
    text-align: center;
    font-size: 1.2rem;
    /* margin-left: -35px; */
}

.about_btn-styled__2-EB2{
     background-color: rgb(139,120,9);
     border-color: rgb(139,120,9) ;
     width: 40%;
     font-size: 1.5rem;
     margin-bottom: 10px;
}

.about_btn-styled__2-EB2 a{
    color: #fff !important;
}

.about_btn-styled__2-EB2:hover{
    background-color: rgb(149,122,20);
    border-color: rgb(139,120,9);
    font-weight: bold;
}

@media(max-width:768px){
    .about_wrapper__1Xnwt{
        flex-direction: column;
    }

    .about_image__lCi-R{
        width: 100% !important;
        background-attachment: fixed;
    }

    .about_content__3u5BZ{
        width: 100%;
    }

    .about_content__3u5BZ p{
        margin-top: -20px;
        font-size: 1.2rem;
    }

    .about_content-inner__32un_{
        margin-left: 0;
        text-align: center;
    }
}
.statItem_stat__ZiNRq h4{
    font-size: 1.2rem !important;
    color:#fff;
    padding: 10px 5px;
}

span{
    font-size: 2rem;
    margin-top: -5px;
}

.statItem_stat__ZiNRq{
    height: 50%;
    width:20rem;
    border-radius: 10px;
    text-align: center;
}

.statItem_stat__ZiNRq:nth-of-type(odd){
    background-color: rgb(139,120,9);
}

.statItem_stat__ZiNRq:nth-of-type(odd) span{
    color: #fff;
}

.statItem_stat__ZiNRq:nth-of-type(even) h4{
    color:rgb(139,120,9)
}

@media all and (max-width: 1024px) {
    .statItem_stat__ZiNRq h4{
        font-size: 1rem !important;
    }
}
span #statItem_punnu-developers__2a3SK{
    padding: 10px;
    font-size: 21px;
    border: 2px solid;
    border-radius: 17px;
  }
h3{
    font-size: 3rem;
    margin-bottom: 20px;
}

#stats_stats__37WPh{
padding: 20px 20px;

}

.stats_stats-wrapper__2CHrS{
    display: flex;
    align-items: center;
    justify-content:space-around;
    height: 15rem;
    margin-top: 20px;
}

@media(max-width:768px){

    h3{
        font-size: 3rem;
        color: aqua;
    }

    .stats_stats-wrapper__2CHrS{
        flex-direction: column;
        justify-content: space-between;
        height: 40rem;
    }
}
.testimonial-item_testimonial-item-wrapper__YWkzN{
    padding: 10px 10px;
}

.testimonial-item_testimonial-image-wrapper__vbiyv{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    background: #ddd;
    
}
.testimonial-item_testimonial-image__2E4k4{
    height: 12rem;
    width: 12rem;
    border-radius: 50%;
}
.footer_left__3Mz3z h4 {
  font-size: 2.2rem;
  font-weight: bolder;
  color: #fff;
}

#footer_footer__39TMn {
  background-color: #fff;
  height: 40rem;
  position: relative;
  overflow-x: hidden;
}

.footer_inner__1Bb4K {
  height: 100%;
  display: flex;
}

.footer_left__3Mz3z {
  height: 100%;
  background-color: rgb(139, 120, 9);
  width: 50%;
  border-bottom-left-radius: 90%;
  display: flex;
  justify-content: space-between;
  padding: 50px 50px;
  padding-right: 100px;
}

.footer_left__3Mz3z ul {
  list-style: none;
  text-align: left;
}

.footer_left__3Mz3z ul li a {
  font-size: 1.5rem;
  line-height: 2.8rem;
  color: #fff !important;
}

.footer_left__3Mz3z ul a:hover {
  color: #0a58ca;
}

.footer_left__3Mz3z .footer_links__1GoAy h5 {
  font-size: 2rem;
  color: #fff;
}

.footer_left__3Mz3z .footer_contact__1izoq h5 {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 55px;
}

.footer_contactInfo__6uapK {
  text-align: left;
  font-size: 1.5rem;
  display: block;
  line-height: 2.4rem;
  color: #fff;
}

.footer_right__Tbxlc {
  height: 100%;
  width: 50%;
  background: rgb(241, 239, 239);
  border-bottom-right-radius: 90%;
  padding: 50px 50px;
}

.footer_right__Tbxlc .footer_newsletter__2ORbX {
  width: 30rem;
  text-align: left;
}

.footer_right__Tbxlc .footer_newsletter__2ORbX h5 {
  font-size: 1.8rem;
  color: #fff;
}

.footer_form-control__3JEHK {
  width: 35rem;
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  border: 2px solid #0adae9;
  justify-content: center;
  align-items: center;
}

label {
  font-size: 1.5rem;
}

input {
  margin-bottom: 20px;
  height: 3rem;
  width: 100%;
  text-align: center;
  font-size: 2rem;
  background-color: #000;
  border: 2px solid #0adae9;
  border-radius: 30px;
  color: #0adae9;
}

input:focus {
  border: 2px solid #0adae9;
  border-radius: 30px;
  height: 3rem;
}

button {
  width: 100%;
  height: 3rem;
  margin: auto;
  background-color: #000;
  border: 2px solid #0adae9;
  border-radius: 30px;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 900;
  padding: 5px 5px;
  transition: all 0.2s ease-in-out;
}

.footer_submit__1VArB {
  margin-top: 10px;
}

button:hover {
  background-color: #0adae9;
  color: #000;
}

.footer_social__1zbL8 {
  padding: 40px 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -50px;
}

.footer_social-item__WPGrb {
  margin-right: 25px;
}

.footer_copy__1f_MX {
  position: absolute;
  bottom: 20px;
  left: 40px;
  font-size: 1.2rem !important;
  color: #fff;
}
/* @media all and (max-width:1024px){
  .form-control{
    width: 100% !important;
    padding: 5px 5px;
  }
  .right .newsletter{
    width: 100%;
  }
} */
@media all and (max-width: 1024px) {
  #footer_footer__39TMn {
    height: 160vh;
    width: 100vw;
    margin-top: 3rem;
    margin-bottom: 6rem;
  }

  .footer_inner__1Bb4K {
    flex-direction: column;
    width: 100%;
  }

  .footer_left__3Mz3z {
    border-bottom-left-radius: 0;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    width: 100%;
    height: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer_left__3Mz3z h4 {
    text-align: center;
    font-size: 2rem;
    margin-left: 50px;
    margin-top: 50px;
  }

  .footer_left__3Mz3z .footer_links__1GoAy h5 {
    font-size: 2rem;
  }

  .footer_links__1GoAy h5 {
    text-align: center;
    margin-left: 40px;
  }

  .footer_left__3Mz3z ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 18rem;
  }

  .footer_left__3Mz3z ul li {
    font-size: 1.2rem;
  }

  .footer_contact__1izoq {
    width: 100%;
    text-align: center;
    margin-left: 40px;
    padding: 20px 20px;
  }

  .footer_left__3Mz3z .footer_contact__1izoq h5 {
    font-size: 2rem;
    margin-bottom: 0;
  }

  .footer_contactInfo__6uapK {
    font-size: 1.2rem;
    text-align: center;
  }

  .footer_right__Tbxlc {
    border-top-left-radius: 0;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    width: 100vw;
    height: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer_right__Tbxlc .footer_newsletter__2ORbX {
    width: 100%;
    text-align: center;
    padding: 10px 10px;
  }

  .footer_right__Tbxlc .footer_newsletter__2ORbX h5 {
    font-size: 1.5rem;
    text-align: center;
  }

  .footer_form-control__3JEHK {
    width: 100%;
    padding: 5px 5px;
  }

  button {
    width: 80%;
    font-weight: lighter;
  }

  .footer_social__1zbL8 {
    margin-left: 0;
  }

  .footer_social-item__WPGrb {
    width: 5rem;
    height: 2rem;
  }

  .footer_copy__1f_MX {
    text-align: center;
    font-size: 1rem;
    bottom: 1%;
    position: relative;
    left: 0%;
    font-size: 1rem !important;
    /* margin-top: 5%; */
    /* width: 15rem; */
  }
}
@media (max-width: 768px) {
  #footer_footer__39TMn {
    height: 160vh;
    width: 100vw;
    margin-top: 3rem;
    margin-bottom: 6rem;
  }

  .footer_inner__1Bb4K {
    flex-direction: column;
    width: 100%;
  }

  .footer_left__3Mz3z {
    border-bottom-left-radius: 0;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    width: 100%;
    height: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer_left__3Mz3z h4 {
    text-align: center;
    font-size: 2rem;
    margin-left: 50px;
    margin-top: 50px;
  }

  .footer_left__3Mz3z .footer_links__1GoAy h5 {
    font-size: 2rem;
  }

  .footer_links__1GoAy h5 {
    text-align: center;
    margin-left: 40px;
  }

  .footer_left__3Mz3z ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 18rem;
  }

  .footer_left__3Mz3z ul li {
    font-size: 1.2rem;
  }

  .footer_contact__1izoq {
    width: 100%;
    text-align: center;
    margin-left: 40px;
    padding: 20px 20px;
  }

  .footer_left__3Mz3z .footer_contact__1izoq h5 {
    font-size: 2rem;
    margin-bottom: 0;
  }

  .footer_contactInfo__6uapK {
    font-size: 1.2rem;
    text-align: center;
  }

  .footer_right__Tbxlc {
    border-top-left-radius: 0;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    width: 100vw;
    height: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer_right__Tbxlc .footer_newsletter__2ORbX {
    width: 100%;
    text-align: center;
    padding: 10px 10px;
  }

  .footer_right__Tbxlc .footer_newsletter__2ORbX h5 {
    font-size: 1.5rem;
    text-align: center;
  }

  .footer_form-control__3JEHK {
    width: 100%;
    padding: 5px 5px;
  }

  button {
    width: 80%;
    font-weight: lighter;
  }

  .footer_social__1zbL8 {
    margin-left: 0;
  }

  .footer_social-item__WPGrb {
    width: 5rem;
    height: 2rem;
  }

  .footer_copy__1f_MX {
    text-align: center;
    bottom: 1%;
    position: relative;
    left: 0%;
    /* margin-top: 5%; */
    /* width: 15rem; */
  }
}

.home_highlight__3gKV7{
    font-size: 4.5rem;
    font-family:Verdana, Geneva, Tahoma, sans-serif ;
    
}

.home_imgStyle__2H1rb {
    -webkit-filter: "blur(1px)";
            filter: "blur(1px)";
    height: "90.8vh";
    background-position: "left left";
  }
  

@media(max-width:768px){
    .home_highlight__3gKV7{
        font-size: 3rem;
    }

    .home_imgStyle__2H1rb {
        -webkit-filter: "blur(1px)";
                filter: "blur(1px)";
        height: "78.8vh";
        background-position: "left left";
      }
      
}
.CarouselCaption_container__31lLd h1 {
  color: #fff !important;
  font-family: "Roboto";
  font-size: 4rem;
  font-weight: bolder !important;
  text-shadow: none;
  margin-right: auto;
  text-align: left;
}

h1 strong {
  text-shadow: 1px 0 #888888;
  letter-spacing: 1px;
  font-weight: bolder;
}

.CarouselCaption_container__31lLd {
  position: absolute;
  /* bottom:25vh; */
  top: 20vh;
  left: 12rem;
  /* right: 20rem; */
  width: 30rem;
  text-align: left;
}

.CarouselCaption_container__31lLd .CarouselCaption_content__3dmLW {
  color: rgb(248, 248, 248);
  font-family: "Roboto";
  font-size: 2rem;
  text-align: left;
  text-shadow: none;
}

.CarouselCaption_focus__2Q4UK {
  border: 3px solid rgb(139, 120, 9);
  border-radius: 50%;
  border-width: medium thin thick 10px;
  padding: 10px 10px;
  font-size: 3rem;
}

.CarouselCaption_infoContainer__3rv4t {
  padding: 50px 2px;
  display: flex;
}

.CarouselCaption_container__31lLd .CarouselCaption_content__3dmLW .CarouselCaption_infoContainer__3rv4t a:hover {
  color: rgb(139, 120, 9);
}

.CarouselCaption_info__3K9Df {
  border: 2px solid rgb(139, 120, 9);
  width: 10rem;
  height: 4rem;
  background-color: inherit;
  border-radius: 10px;
  font-size: 15px;
  color: #fff !important;
  margin-right: 50px;
  margin-bottom: 10px;
  font-size: 20px;
  text-align: center;
  font-weight: bolder;
}

.CarouselCaption_info-know__3DDJ3 a {
  color: whitesmoke !important;
}

.CarouselCaption_info-know__3DDJ3:hover {
  background-color: rgb(149, 122, 20) !important;
}
.CarouselCaption_info-donate__2Or02 {
  background: rgb(139, 120, 9);
  background-blend-mode: darken;
  width: 10rem;
  height: 4rem;
  color: black;
  font-weight: bolder;
  padding: 1.1rem 2rem;
}

.CarouselCaption_info-donate__2Or02:hover {
  background-color: rgb(149, 122, 20);
  border-color: rgb(139, 120, 9);
  color: rgb(253, 253, 253);
  font-weight: bold;
}

@media (max-width: 768px) {
  .CarouselCaption_container__31lLd {
    position: absolute;
    top: 100px;
    left: 10px;
    width: 100vw;
    font-size: 1.5rem;
    text-align: left;
    padding: 0 20px;
  }

  .CarouselCaption_container__31lLd h1 {
    font-size: 3.5rem;
  }

  .CarouselCaption_container__31lLd .CarouselCaption_content__3dmLW {
    font-size: 1.5rem;
    width: 100%;
    line-height: 3.5rem;
  }

  .CarouselCaption_container__31lLd .CarouselCaption_content__3dmLW .CarouselCaption_focus__2Q4UK {
    font-size: 1.8rem;
  }

  .CarouselCaption_infoContainer__3rv4t {
    width: 100vw;
    justify-content: center;
    align-items: center;
    margin-left: -10px;
  }

  .CarouselCaption_info-donate__2Or02 {
    height: 4rem;
  }
}

.buttonStyle_button__spMhV {
  height: 5rem;
  width: 5rem;
  position: absolute;
  top: 20%;
  left: 2rem;
  border: 1px solid rgb(139, 120, 9);
  border-radius: 50%;
  background: rgb(139, 120, 9);
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translateX(-1000px);
          transform: translateX(-1000px);
}

.buttonStyle_right__H_sQw{
    left:calc(100vw - 8rem);
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
}

.buttonStyle_show__3rDQo{
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

@media(max-width:500px){
    .buttonStyle_button__spMhV{
        height: 3rem;
        width: 3rem;
        left: 0.5rem;
    }
    .buttonStyle_right__H_sQw{
        left: 19rem;
    }
}

.logOut-btn{
    border:none;
}
.top{
  width: 100vw;
  height: 60px ;
  background-color: whitesmoke;
  position: sticky;
  top :0;
  display:flex;
  align-items: center;
  font-family: 'Josefin Sans', sans-serif;
  z-index: 999;
}
.topLeft , .topRight{
  flex :2 1;
  display:flex;
  align-items: center;
  justify-content: center;    
}
.topCenter{
  flex:8 1;
}
.topIcon {
  font-size: 25px;
  margin-right: 10px;
  color: rgb(82, 52, 52);
  cursor: pointer;

}
.topImg{
  width: 40px;
  height:40px;
  border-radius:50%;
  object-fit:cover;
  margin-left: 80px;
}
.topList{
  display:flex;
  justify-content: center;
  margin:0;
  list-style:none;
}
.topListItem{
  display:flex;
  font-size: 1.3rem;
  margin-right: 25px;
  font-weight: 400;
  cursor: pointer;
  color:#000;
}

.topSearchIcon{
 font-size: 18px;
 color:rgb(48, 22, 22);
 cursor: pointer;
 
}


.headerTitles{
  /* display : flex;
  flex-direction: column;
  align-items: center; */
  font-family: 'Lora', serif;
  color:#444 ;
  font-weight: 800;

}

.headerTitleSm{
  font-size:60px;
}
.headerTitleLg{
  font-size:80px;
}
.headerImg{
  width: 100%;
  height:850px;
  margin-top: 80px;
  object-fit: cover;

}
.downLine{
  margin-bottom: -79px;
  border: 2px solid;
}
.posts{
  flex : 9 1  ! important;
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
}
.post{
  width:420px;
  margin-top: 10px;
  margin-right: 25px;
  margin-bottom: 40px;
  margin-left: 25px;
  border: 1px solid;
  padding: 15px;
  border-radius: 15px;
}
.postImg{
  width: 100%;
  height: 280px;
  object-fit: cover;
  border-radius: 10px;
}
.postInfo{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.postCats{
  padding-top: 25px;
}
.postCat{

  font-family: "Varela Round" , sans-serif;
  font-size: 16px;
  color:#be9656;
  margin-top: 20px;
  line-height: 20px;
  margin-right: 15px;
  cursor: pointer;
  font-weight: 800;
}
.postTitle{

  font-family: 'Josefin Sans' , sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin-top: 15px;
  cursor: pointer;
}
.postDate{
  font-family: 'Lora' , serif;
  font-style: italic;
  color: #999 ;
  /* margin-top: 15px; */
}
.postDesc{
  font-family: 'Varela Roound' , sans-serif;
  font-size: 15px;
  color: #444;
  margin-top: 5px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
hr.roll{
  margin: 0px !important;
  width: 4px;
  height: 50px;
}
@media all and (max-width:430px) {
  .posts{
    width: 100%;
    margin-left: 0px;
  }
}
.home {
  display: grid;
}

.loader{
 display: flex;
 height: 100vh;
 align-items: center;
 justify-content: center;
}

.loader svg{
  height: 10%;
}

@media all and (max-width:430px) {
  .home{
    display: block;
  }
}

.singlePost {
  flex: 9 1;
  overflow: hidden;
  padding-bottom: 5rem;
}
.singlePostWrapper {
  padding-right: 10px;
  padding: 15px;
}

.singlePostImg {
  width: 50%;
  height: 30rem;
  border-radius: 5px;
  object-fit: cover;
  margin: auto;
}

.singlePostTitle {
  text-align: center;
  margin: 10px;
  font-family: "Roboto";
  /* font-size: 34px; */
}
.singlePosting {
  border-radius: 2.5%;
}

.singlePostEdit {
  float: right;
  font-size: 20px;
  margin-top: 17px;
}
h1.singlePostTitle {
  color: rgb(139,120,9) !important;
  border-radius: 10px;
  border: 1px dashed;
}

.singlePostIcon {
  margin-right: 15px;
  cursor: pointer;
}
.singlePostIcon:first-child {
  color: teal;
}

.singlePostIcon:last-child {
  color: tomato;
}

.singlePostInfo {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  font-family: "Varela Round", sans-serif;
  color: rgb(139,120,9);
}

.singlePostDesc,
.subPostDesc {
  color: #000;
  font-size: 1.8rem;
  line-height: 2.2rem;
  flex: 9 1 !important;
  white-space: pre-line;
  text-align: left;
}

.singlePostDesc::first-letter {
  margin-left: 20px;
  font-size: 40px;
  font-weight: 600;
}
hr.roll {
  margin: 0 !important;
  margin-left: 50% !important;
}

.social-media-container {
  display: flex;
  justify-items: center;
  align-items: center;
  margin: 0 auto;
  width: 10%;
}

@media all and (max-width: 430px) {
  .singlePost {
    width: 100%;
  }

  .singlePostDesc,
  .subPostDesc {
    font-size: 1.2rem;
  }

  .singlePostInfo {
    flex-direction: column;
  }

  .singlePostImg {
    height: 25rem;
    width: 100%;
  }

  .social-media-container {
    width: 30%;
  }
}

.single {
  display: flex;
}

.loader {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.loader svg {
  height: 10%;
}

@media all and (max-width: 430px) {
  .single {
    display: block;
  }
}

.write{
  padding-top: 50px;

}
.writeForm{
position: relative;
}

.writeFormGroup{
  /* margin-left: 50px; */
  display: flex;
  align-items: center;
  margin-bottom:35px;
  margin-top: 5px;
  
}

.writeIcon{
 width: 30px;
 height: 30px;
 border-radius: 50%;
 border:2px solid;
 display: flex;
 align-items: center;
 justify-content: center;
 margin-right: 10px;
 font-size: 25px;
 color: rgb(29, 24, 24);
 cursor: pointer;
 
}

.writeInput{
  font-size: 25px;
  margin-top: 18px;
  
}
.writeInput:focus {
  outline:none;
}

.writeText{
  /* font-size: 20px; */
  height: 120px;
  padding: 10px;
  color: black;
  font-weight: 600;
  border: 2px solid cadetblue;
  border-radius: 10px;
  margin: auto;
  width: 100%;
  white-space: pre-wrap;
}

.writeSubmit{
  /* position: absolute;
 top: 04px; */
 /* left: 980px; */
 right: 150px;
 color:ivory;
 background-color : rgb(24, 95, 95);
 padding:10px;
 border:none;
 border-radius: 10px;
 font-size: 16px;
 cursor: pointer;
 margin-top: 15px;
 letter-spacing: 0.1rem;
 display: flex;
 align-items: center;
 justify-content: center;
}

.writeSubmit h3{
  color: #fff !important;
  width: 50%;
}

.writeSubmit .loader{
  height: 20rem;
  width: 50%;
}

.writeImg{
  /* margin-left: 150px; */
  /* width:60vw; */
  height: 250px;
  object-fit:cover;
  /* border-radius: 10px; */
}
input#fileInput{
  padding: 5px;
}
input.writeInput{
  margin-top: 33px;
}
.fuckinDivideThem{
  width: 50%;
}


@media all and (max-width:470px) {
  .writeFormGroup{
    display: block;
  }
  input.writeInput{
    margin-top: 20px;
  }
  .fuckinDivideThem{
    width: 100%;
  }
}
.settings{
  display: flex;
}

.settingsWrapper{
  flex:9 1;
  padding: 20px;
}

.settingsTitle{

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settingsUpdateTitle{
  font-size: 30px;
  margin-bottom: 20px;
  color:lightcoral;
  font-weight:600;
  font-family: sans-serif;
}
.settingsDeleteTitle{
 color: red;
 font-size: 19px;
 cursor: pointer;
}

.settingsForm{
  display: flex;
  flex-direction: column;
}

.settingsPP{
  display: flex;
  align-items: center;
 margin: 10px 0 ;

}

.settingsPP >img{
  width: 70px;
  height: 70px;
  border-radius: 18px;
  object-fit: cover ;
}

.settingsPPIcon{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: lightcoral;
  color: whitesmokes;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}

.settingsForm > label{

  font-size: 20px;
  margin-top: 20px;
}

.settingsForm > input {
  color: gray;
  margin: 10px 0;
  height: 30px;
  border: none;
  border-bottom: 1px solid lightgray;
}

.settingsSubmit{
  width: 150px;
  align-self: center;
  border:none;
  border-radius: 10px;
  color: whitesmoke;
  background-color: teal;
  height: 35px;
  margin-top: 15px;

}
.login{
  height: calc(100vh - 60px);
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center !important;
  background :linear-gradient(rgba(235, 160, 160, 0.5) , rgba(255,255,255,0.5)) , url("https://image.shutterstock.com/image-vector/man-key-near-computer-account-260nw-1499141258.jpg");
  background-size: cover;
  object-fit: cover;
}

.loginTitle{
  font-size: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
}
.loginForm{
  margin-top:45px;
  display: flex;
  flex-direction: column;
}

.loginForm >label {
  margin: 10px 0;
}

.loginInput{
  padding: 14px;
  background-color: white;
  border:none;
}

.loginButton{
  margin-top: 20px;cursor: pointer;
  background-color: rgb(201, 67, 67);
  border:none;
  color:white;
 border-radius: 10px;
 margin-bottom: 10px;
 height: 32px;
}

.loginRegisterButton{
  position: absolute;
  top: 60px;
  right: 20px;
  background-color:  teal ;
  cursor: pointer;
  border:none;
  padding: 10px;
  color: beige;
  border-radius: 10px;
  margin-top: 25px;
  width: 120px;
}
.register{
  height: calc(100vh - 60px);
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center !important;
  background :linear-gradient(rgba(235, 160, 160, 0.5) , rgba(255,255,255,0.5)) , url("https://cdn.pixabay.com/photo/2014/08/26/21/29/padlock-428549_960_720.jpg");
  background-size: cover;
  object-fit: cover;
}

.registerTitle{
  font-size: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
}
.registerForm{
  margin-top:45px;
  display: flex;
  flex-direction: column;
}

.registerForm >label {
  margin: 10px 0;
}

.registerInput{
  padding: 14px;
  background-color: white;
  border:none;
}

.registerButton{
  margin-top: 20px;cursor: pointer;
  background-color:  teal;
  border:none;
  color:white;
 border-radius: 10px;
 margin-bottom: 10px;
 height: 38px;
}

.registerLoginButton{
  position: absolute;
  top: 60px;
  right: 20px;
  background-color:  rgb(201, 67, 67) ;
  cursor: pointer;
  border:none;
  padding: 10px;
  color: beige;
  border-radius: 10px;
  margin-top: 25px;
  width: 120px;
}

.register input{
  color:#000;
}
