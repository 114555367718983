.button {
  height: 5rem;
  width: 5rem;
  position: absolute;
  top: 20%;
  left: 2rem;
  border: 1px solid rgb(139, 120, 9);
  border-radius: 50%;
  background: rgb(139, 120, 9);
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
  transform: translateX(-1000px);
}

.right{
    left:calc(100vw - 8rem);
    transform: translateX(1000px);
}

.show{
    transform: translateX(0);
}

@media(max-width:500px){
    .button{
        height: 3rem;
        width: 3rem;
        left: 0.5rem;
    }
    .right{
        left: 19rem;
    }
}
