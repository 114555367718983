.card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* padding: 5px 20px; */
  justify-items: center;
  align-items: center;
  gap: 4px;
  grid-auto-flow: row;
}

.card {
  border: 1px solid black;
  /* border-radius: 10px; */
  margin: 5px 5px;
  color: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  height: 32rem;
  width: 25rem;
  margin-bottom: 80px;
  position: relative;
}

.card span {
  text-shadow: -1px 1px 0 rgb(139, 120, 9), 1px 1px 0 rgb(139, 120, 9),
    1px -1px 0 rgb(139, 120, 9), -1px -1px 0 rgb(139, 120, 9);
  color: #fff;
  transition: all ease-in 0.5s;
}

.card:hover span {
  color: rgb(139, 120, 9);
}

.top {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  background-color: rgb(139, 120, 9);
  height: 5px;
  transition: all 0.5s ease-in-out;
  animation: topexit 0.5s ease-in-out forwards;
}

.card:hover .top {
  transition: all 0.5s ease-in-out;
  animation: topentry 0.5s ease-in-out forwards;
}

@keyframes topentry {
  from {
    transition: all 0.5s ease-in-out;
    width: 0;
  }
  to {
    transition: all 0.5s ease-in-out;
    width: 100%;
  }
}

@keyframes topexit {
  from {
    transition: all 0.5s ease-in-out;
    width: 100%;
  }
  to {
    transition: all 0.5s ease-in-out;
    width: 0;
  }
}

.left {
  position: absolute;
  left: 0;
  width: 5px;
  background-color: rgb(139, 120, 9);
  height: 0;
  animation: leftexit 0.5s ease-in-out forwards;
}

.card:hover .left {
  transition: all 0.5s ease-in-out;
  animation: leftentry 0.5s ease-in-out forwards;
}

@keyframes leftentry {
  from {
    transition: all 0.5s ease-in-out;
    height: 0;
  }
  to {
    transition: all 0.5s ease-in-out;
    height: 100%;
  }
}

@keyframes leftexit {
  from {
    transition: all 0.5s ease-in-out;
    height: 100%;
  }
  to {
    transition: all 0.5s ease-in-out;
    height: 0;
  }
}

.right {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 5px;
  background-color: rgb(139, 120, 9);
  height: 0;
  animation: rightexit 0.5s ease-in-out forwards;
}

.card:hover .right {
  transition: all 0.5s ease-in-out;
  animation: rightentry 0.5s ease-in-out forwards;
}

@keyframes rightentry {
  from {
    transition: all 0.5s ease-in-out;
    height: 0;
  }
  to {
    transition: all 0.5s ease-in-out;
    height: 100%;
  }
}

@keyframes rightexit {
  from {
    transition: all 0.5s ease-in-out;
    height: 100%;
  }
  to {
    transition: all 0.5s ease-in-out;
    height: 0;
  }
}

.bottom {
  position: absolute;
  bottom: 0;
  width: 0;
  background-color: rgb(139, 120, 9);
  height: 5px;
  animation: bottomexit 0.5s ease-in-out forwards;
}

.card:hover .bottom {
  transition: all 0.5s ease-in-out;
  animation: bottomentry 0.5s ease-in-out forwards;
}

@keyframes bottomentry {
  from {
    transition: all 0.5s ease-in-out;
    width: 0;
  }
  to {
    transition: all 0.5s ease-in-out;
    width: 100%;
  }
}

@keyframes bottomexit {
  from {
    transition: all 0.5s ease-in-out;
    width: 100%;
  }
  to {
    transition: all 0.5s ease-in-out;
    width: 0;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-content: center;
  }

  .card {
    width: 18rem;
    height: 30rem;
  }
}
