
.headerTitles{
  /* display : flex;
  flex-direction: column;
  align-items: center; */
  font-family: 'Lora', serif;
  color:#444 ;
  font-weight: 800;

}

.headerTitleSm{
  font-size:60px;
}
.headerTitleLg{
  font-size:80px;
}
.headerImg{
  width: 100%;
  height:850px;
  margin-top: 80px;
  object-fit: cover;

}
.downLine{
  margin-bottom: -79px;
  border: 2px solid;
}