.register{
  height: calc(100vh - 60px);
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center !important;
  background :linear-gradient(rgba(235, 160, 160, 0.5) , rgba(255,255,255,0.5)) , url("https://cdn.pixabay.com/photo/2014/08/26/21/29/padlock-428549_960_720.jpg");
  background-size: cover;
  object-fit: cover;
}

.registerTitle{
  font-size: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
}
.registerForm{
  margin-top:45px;
  display: flex;
  flex-direction: column;
}

.registerForm >label {
  margin: 10px 0;
}

.registerInput{
  padding: 14px;
  background-color: white;
  border:none;
}

.registerButton{
  margin-top: 20px;cursor: pointer;
  background-color:  teal;
  border:none;
  color:white;
 border-radius: 10px;
 margin-bottom: 10px;
 height: 38px;
}

.registerLoginButton{
  position: absolute;
  top: 60px;
  right: 20px;
  background-color:  rgb(201, 67, 67) ;
  cursor: pointer;
  border:none;
  padding: 10px;
  color: beige;
  border-radius: 10px;
  margin-top: 25px;
  width: 120px;
}

.register input{
  color:#000;
}