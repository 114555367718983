.testimonial-item-wrapper{
    padding: 10px 10px;
}

.testimonial-image-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    background: #ddd;
    
}
.testimonial-image{
    height: 12rem;
    width: 12rem;
    border-radius: 50%;
}