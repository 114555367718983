.left h4 {
  font-size: 2.2rem;
  font-weight: bolder;
  color: #fff;
}

#footer {
  background-color: #fff;
  height: 40rem;
  position: relative;
  overflow-x: hidden;
}

.inner {
  height: 100%;
  display: flex;
}

.left {
  height: 100%;
  background-color: rgb(139, 120, 9);
  width: 50%;
  border-bottom-left-radius: 90%;
  display: flex;
  justify-content: space-between;
  padding: 50px 50px;
  padding-right: 100px;
}

.left ul {
  list-style: none;
  text-align: left;
}

.left ul li a {
  font-size: 1.5rem;
  line-height: 2.8rem;
  color: #fff !important;
}

.left ul a:hover {
  color: #0a58ca;
}

.left .links h5 {
  font-size: 2rem;
  color: #fff;
}

.left .contact h5 {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 55px;
}

.contactInfo {
  text-align: left;
  font-size: 1.5rem;
  display: block;
  line-height: 2.4rem;
  color: #fff;
}

.right {
  height: 100%;
  width: 50%;
  background: rgb(241, 239, 239);
  border-bottom-right-radius: 90%;
  padding: 50px 50px;
}

.right .newsletter {
  width: 30rem;
  text-align: left;
}

.right .newsletter h5 {
  font-size: 1.8rem;
  color: #fff;
}

.form-control {
  width: 35rem;
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  border: 2px solid #0adae9;
  justify-content: center;
  align-items: center;
}

label {
  font-size: 1.5rem;
}

input {
  margin-bottom: 20px;
  height: 3rem;
  width: 100%;
  text-align: center;
  font-size: 2rem;
  background-color: #000;
  border: 2px solid #0adae9;
  border-radius: 30px;
  color: #0adae9;
}

input:focus {
  border: 2px solid #0adae9;
  border-radius: 30px;
  height: 3rem;
}

button {
  width: 100%;
  height: 3rem;
  margin: auto;
  background-color: #000;
  border: 2px solid #0adae9;
  border-radius: 30px;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 900;
  padding: 5px 5px;
  transition: all 0.2s ease-in-out;
}

.submit {
  margin-top: 10px;
}

button:hover {
  background-color: #0adae9;
  color: #000;
}

.social {
  padding: 40px 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -50px;
}

.social-item {
  margin-right: 25px;
}

.copy {
  position: absolute;
  bottom: 20px;
  left: 40px;
  font-size: 1.2rem !important;
  color: #fff;
}
/* @media all and (max-width:1024px){
  .form-control{
    width: 100% !important;
    padding: 5px 5px;
  }
  .right .newsletter{
    width: 100%;
  }
} */
@media all and (max-width: 1024px) {
  #footer {
    height: 160vh;
    width: 100vw;
    margin-top: 3rem;
    margin-bottom: 6rem;
  }

  .inner {
    flex-direction: column;
    width: 100%;
  }

  .left {
    border-bottom-left-radius: 0;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    width: 100%;
    height: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .left h4 {
    text-align: center;
    font-size: 2rem;
    margin-left: 50px;
    margin-top: 50px;
  }

  .left .links h5 {
    font-size: 2rem;
  }

  .links h5 {
    text-align: center;
    margin-left: 40px;
  }

  .left ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 18rem;
  }

  .left ul li {
    font-size: 1.2rem;
  }

  .contact {
    width: 100%;
    text-align: center;
    margin-left: 40px;
    padding: 20px 20px;
  }

  .left .contact h5 {
    font-size: 2rem;
    margin-bottom: 0;
  }

  .contactInfo {
    font-size: 1.2rem;
    text-align: center;
  }

  .right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    width: 100vw;
    height: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .right .newsletter {
    width: 100%;
    text-align: center;
    padding: 10px 10px;
  }

  .right .newsletter h5 {
    font-size: 1.5rem;
    text-align: center;
  }

  .form-control {
    width: 100%;
    padding: 5px 5px;
  }

  button {
    width: 80%;
    font-weight: lighter;
  }

  .social {
    margin-left: 0;
  }

  .social-item {
    width: 5rem;
    height: 2rem;
  }

  .copy {
    text-align: center;
    font-size: 1rem;
    bottom: 1%;
    position: relative;
    left: 0%;
    font-size: 1rem !important;
    /* margin-top: 5%; */
    /* width: 15rem; */
  }
}
@media (max-width: 768px) {
  #footer {
    height: 160vh;
    width: 100vw;
    margin-top: 3rem;
    margin-bottom: 6rem;
  }

  .inner {
    flex-direction: column;
    width: 100%;
  }

  .left {
    border-bottom-left-radius: 0;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    width: 100%;
    height: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .left h4 {
    text-align: center;
    font-size: 2rem;
    margin-left: 50px;
    margin-top: 50px;
  }

  .left .links h5 {
    font-size: 2rem;
  }

  .links h5 {
    text-align: center;
    margin-left: 40px;
  }

  .left ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 18rem;
  }

  .left ul li {
    font-size: 1.2rem;
  }

  .contact {
    width: 100%;
    text-align: center;
    margin-left: 40px;
    padding: 20px 20px;
  }

  .left .contact h5 {
    font-size: 2rem;
    margin-bottom: 0;
  }

  .contactInfo {
    font-size: 1.2rem;
    text-align: center;
  }

  .right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    width: 100vw;
    height: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .right .newsletter {
    width: 100%;
    text-align: center;
    padding: 10px 10px;
  }

  .right .newsletter h5 {
    font-size: 1.5rem;
    text-align: center;
  }

  .form-control {
    width: 100%;
    padding: 5px 5px;
  }

  button {
    width: 80%;
    font-weight: lighter;
  }

  .social {
    margin-left: 0;
  }

  .social-item {
    width: 5rem;
    height: 2rem;
  }

  .copy {
    text-align: center;
    bottom: 1%;
    position: relative;
    left: 0%;
    /* margin-top: 5%; */
    /* width: 15rem; */
  }
}
