.singlePost {
  flex: 9;
  overflow: hidden;
  padding-bottom: 5rem;
}
.singlePostWrapper {
  padding-right: 10px;
  padding: 15px;
}

.singlePostImg {
  width: 50%;
  height: 30rem;
  border-radius: 5px;
  object-fit: cover;
  margin: auto;
}

.singlePostTitle {
  text-align: center;
  margin: 10px;
  font-family: "Roboto";
  /* font-size: 34px; */
}
.singlePosting {
  border-radius: 2.5%;
}

.singlePostEdit {
  float: right;
  font-size: 20px;
  margin-top: 17px;
}
h1.singlePostTitle {
  color: rgb(139,120,9) !important;
  border-radius: 10px;
  border: 1px dashed;
}

.singlePostIcon {
  margin-right: 15px;
  cursor: pointer;
}
.singlePostIcon:first-child {
  color: teal;
}

.singlePostIcon:last-child {
  color: tomato;
}

.singlePostInfo {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  font-family: "Varela Round", sans-serif;
  color: rgb(139,120,9);
}

.singlePostDesc,
.subPostDesc {
  color: #000;
  font-size: 1.8rem;
  line-height: 2.2rem;
  flex: 9 !important;
  white-space: pre-line;
  text-align: left;
}

.singlePostDesc::first-letter {
  margin-left: 20px;
  font-size: 40px;
  font-weight: 600;
}
hr.roll {
  margin: 0 !important;
  margin-left: 50% !important;
}

.social-media-container {
  display: flex;
  justify-items: center;
  align-items: center;
  margin: 0 auto;
  width: 10%;
}

@media all and (max-width: 430px) {
  .singlePost {
    width: 100%;
  }

  .singlePostDesc,
  .subPostDesc {
    font-size: 1.2rem;
  }

  .singlePostInfo {
    flex-direction: column;
  }

  .singlePostImg {
    height: 25rem;
    width: 100%;
  }

  .social-media-container {
    width: 30%;
  }
}
