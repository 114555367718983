.write{
  padding-top: 50px;

}
.writeForm{
position: relative;
}

.writeFormGroup{
  /* margin-left: 50px; */
  display: flex;
  align-items: center;
  margin-bottom:35px;
  margin-top: 5px;
  
}

.writeIcon{
 width: 30px;
 height: 30px;
 border-radius: 50%;
 border:2px solid;
 display: flex;
 align-items: center;
 justify-content: center;
 margin-right: 10px;
 font-size: 25px;
 color: rgb(29, 24, 24);
 cursor: pointer;
 
}

.writeInput{
  font-size: 25px;
  margin-top: 18px;
  
}
.writeInput:focus {
  outline:none;
}

.writeText{
  /* font-size: 20px; */
  height: 120px;
  padding: 10px;
  color: black;
  font-weight: 600;
  border: 2px solid cadetblue;
  border-radius: 10px;
  margin: auto;
  width: 100%;
  white-space: pre-wrap;
}

.writeSubmit{
  /* position: absolute;
 top: 04px; */
 /* left: 980px; */
 right: 150px;
 color:ivory;
 background-color : rgb(24, 95, 95);
 padding:10px;
 border:none;
 border-radius: 10px;
 font-size: 16px;
 cursor: pointer;
 margin-top: 15px;
 letter-spacing: 0.1rem;
 display: flex;
 align-items: center;
 justify-content: center;
}

.writeSubmit h3{
  color: #fff !important;
  width: 50%;
}

.writeSubmit .loader{
  height: 20rem;
  width: 50%;
}

.writeImg{
  /* margin-left: 150px; */
  /* width:60vw; */
  height: 250px;
  object-fit:cover;
  /* border-radius: 10px; */
}
input#fileInput{
  padding: 5px;
}
input.writeInput{
  margin-top: 33px;
}
.fuckinDivideThem{
  width: 50%;
}


@media all and (max-width:470px) {
  .writeFormGroup{
    display: block;
  }
  input.writeInput{
    margin-top: 20px;
  }
  .fuckinDivideThem{
    width: 100%;
  }
}