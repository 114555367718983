h3{
    font-size: 3rem;
    margin-bottom: 20px;
}

#stats{
padding: 20px 20px;

}

.stats-wrapper{
    display: flex;
    align-items: center;
    justify-content:space-around;
    height: 15rem;
    margin-top: 20px;
}

@media(max-width:768px){

    h3{
        font-size: 3rem;
        color: aqua;
    }

    .stats-wrapper{
        flex-direction: column;
        justify-content: space-between;
        height: 40rem;
    }
}