.home {
  display: grid;
}

.loader{
 display: flex;
 height: 100vh;
 align-items: center;
 justify-content: center;
}

.loader svg{
  height: 10%;
}

@media all and (max-width:430px) {
  .home{
    display: block;
  }
}
