.stat h4{
    font-size: 1.2rem !important;
    color:#fff;
    padding: 10px 5px;
}

span{
    font-size: 2rem;
    margin-top: -5px;
}

.stat{
    height: 50%;
    width:20rem;
    border-radius: 10px;
    text-align: center;
}

.stat:nth-of-type(odd){
    background-color: rgb(139,120,9);
}

.stat:nth-of-type(odd) span{
    color: #fff;
}

.stat:nth-of-type(even) h4{
    color:rgb(139,120,9)
}

@media all and (max-width: 1024px) {
    .stat h4{
        font-size: 1rem !important;
    }
}
span #punnu-developers{
    padding: 10px;
    font-size: 21px;
    border: 2px solid;
    border-radius: 17px;
  }