.top{
  width: 100vw;
  height: 60px ;
  background-color: whitesmoke;
  position: sticky;
  top :0;
  display:flex;
  align-items: center;
  font-family: 'Josefin Sans', sans-serif;
  z-index: 999;
}
.topLeft , .topRight{
  flex :2;
  display:flex;
  align-items: center;
  justify-content: center;    
}
.topCenter{
  flex:8;
}
.topIcon {
  font-size: 25px;
  margin-right: 10px;
  color: rgb(82, 52, 52);
  cursor: pointer;

}
.topImg{
  width: 40px;
  height:40px;
  border-radius:50%;
  object-fit:cover;
  margin-left: 80px;
}
.topList{
  display:flex;
  justify-content: center;
  margin:0;
  list-style:none;
}
.topListItem{
  display:flex;
  font-size: 1.3rem;
  margin-right: 25px;
  font-weight: 400;
  cursor: pointer;
  color:#000;
}

.topSearchIcon{
 font-size: 18px;
 color:rgb(48, 22, 22);
 cursor: pointer;
 
}
