.single {
  display: flex;
}

.loader {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.loader svg {
  height: 10%;
}

@media all and (max-width: 430px) {
  .single {
    display: block;
  }
}
