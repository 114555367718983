/* @import url('https://fonts.googleapis.com/css2?family=Anton&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@300&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

.nav-head{
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-logo {
  height: 5rem;
}

.navbar-brand {
  font-size: 1.5rem !important;
  font-weight: bolder;
  color: rgb(139,120,6) !important;
  border-bottom: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.navbar-brand:hover {
  border-bottom: none !important;
  color: rgb(160, 172, 168);
  /* text-decoration: none; */
}

.navbar a {
  font-size: 1 rem;
  text-transform: capitalize;
  font-weight: 600;
}

.navbar ul li {
  padding: 5px;
  /* margin-right: 38px; */
}

.menu_active {
  font-weight: bold;
}

.navbar a:hover {
  color: rgb(139,120,6) !important;
  font-weight: bolder;
  border-bottom: 0.2rem solid rgb(139,120,6);
  border-bottom-width: thin;
}

.menu_active {
  font-weight: bold;
  border-bottom: 0.2rem solid#000;
  color: #000 !important;
}
@media all and (max-width: 1024px) {
  .navbar ul li{
    padding: 2px;
  }
}
@media all and (max-width: 990px) {
  .navbar-brand {
    font-size: 2rem !important;
    border-bottom: none;
  }
  .logo1{
    margin-left: -80px !important;
  }
  .title1{
    margin-right: 27%!important;
  }
  button.navbar-toggler{
    margin-left: 99%;
    margin-top: -68px;
  }
}
@media all and (max-width: 768px) {
  .navbar-brand {
    font-size: 2rem !important;
    border-bottom: none;
  }
  .logo1{
    margin-left: -80px !important;
  }
  .title1{
    margin-right: 27%!important;
    padding-top: 20px !important;
  }
  button.navbar-toggler{
    margin-left: 99%;
    margin-top: -68px;
  }
}
@media all and (max-width: 580px) {
  .navbar-brand {
    font-size: 2rem !important;
    border-bottom: none;
  }
  .logo1{
    margin-left: -80px !important;
  }
  .title1{
    margin-right: 18%!important;
  }
  button.navbar-toggler{
    margin-left: 99%;
    margin-top: -68px;
  }
}
@media all and (max-width: 498px) {
  .navbar-brand {
    font-size: 2rem !important;
    border-bottom: none;
  }
  .logo1{
    margin-left: -80px !important;
  }
  .title1{
    margin-right: 15%!important;
  }
  button.navbar-toggler{
    margin-left: 99%;
    margin-top: -68px;
  }
}
@media all and (max-width: 470px) {
  .navbar-brand {
    font-size: 2rem !important;
    border-bottom: none;
  }
  .logo1{
    margin-left: -70px !important;
  }
  .title1{
    margin-right: 25%!important;
    font-size: 20px !important;
  }
  button.navbar-toggler{
    margin-left: 99%;
    margin-top: -68px;
  }
}
/* @media all and (max-width: 430px){
  .logo1{
    margin-left: -28% !important;
  }
  .title1{
    margin-left: 19%;
    font-size: 20px!important;
    margin-top: -44%;
  }
  button.navbar-toggler{
    margin-left: 93%;
    margin-top: -44%;
  }
} */
@media all and (max-width: 400px){
  .logo1{
    margin-left: -28% !important;
  }
  .title1{
    margin-left: 15%;
    /* font-size: 20px!important; */
    margin-top: -35%;
  }
  button.navbar-toggler{
    margin-left: 93%;
    margin-top: -28%;
  }
  #navbarSupportedContent{
    margin-left: 15px;
  }
}
@media all and (max-width: 360px){
  .logo1{
    margin-left: -30% !important;
  }
  .title1{
    margin-left: 15%;
    font-size: 18px!important;
    /* margin-top: -40%; */
  }
  button.navbar-toggler{
    margin-left: 93%;
    margin-top: -30%;
  }
}

@media all and (max-width: 320px){
  .logo1{
    margin-left: -37% !important;
  }
  .title1{
    margin-left: 14%;
    font-size: 18px!important;
    /* margin-top: -48%; */
  }
  button.navbar-toggler{
    margin-left: 93%;
    margin-top: -38%;
  }
}
