.section{
    height:80vh;
    width: 100%;
}

.wrapper{
    display: flex;
}

.image{
    background-color: #fff;
    width:50%;
}

.content{
    width: 50%;
    background-color: #ddd;
}

.content-inner{
    border: 1px solid #fff;
    margin-left: -50px;
    margin-top: 50px;
    background-color: #fff;
}

.content p{
    color: #000;
    margin: 50px;
    text-align: center;
    font-size: 1.2rem;
    /* margin-left: -35px; */
}

.btn-styled{
     background-color: rgb(139,120,9);
     border-color: rgb(139,120,9) ;
     width: 40%;
     font-size: 1.5rem;
     margin-bottom: 10px;
}

.btn-styled a{
    color: #fff !important;
}

.btn-styled:hover{
    background-color: rgb(149,122,20);
    border-color: rgb(139,120,9);
    font-weight: bold;
}

@media(max-width:768px){
    .wrapper{
        flex-direction: column;
    }

    .image{
        width: 100% !important;
        background-attachment: fixed;
    }

    .content{
        width: 100%;
    }

    .content p{
        margin-top: -20px;
        font-size: 1.2rem;
    }

    .content-inner{
        margin-left: 0;
        text-align: center;
    }
}