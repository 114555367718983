.login{
  height: calc(100vh - 60px);
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center !important;
  background :linear-gradient(rgba(235, 160, 160, 0.5) , rgba(255,255,255,0.5)) , url("https://image.shutterstock.com/image-vector/man-key-near-computer-account-260nw-1499141258.jpg");
  background-size: cover;
  object-fit: cover;
}

.loginTitle{
  font-size: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
}
.loginForm{
  margin-top:45px;
  display: flex;
  flex-direction: column;
}

.loginForm >label {
  margin: 10px 0;
}

.loginInput{
  padding: 14px;
  background-color: white;
  border:none;
}

.loginButton{
  margin-top: 20px;cursor: pointer;
  background-color: rgb(201, 67, 67);
  border:none;
  color:white;
 border-radius: 10px;
 margin-bottom: 10px;
 height: 32px;
}

.loginRegisterButton{
  position: absolute;
  top: 60px;
  right: 20px;
  background-color:  teal ;
  cursor: pointer;
  border:none;
  padding: 10px;
  color: beige;
  border-radius: 10px;
  margin-top: 25px;
  width: 120px;
}