.App {
  text-align: center;
  background-color: rgb(241, 239, 239);
  color:#000;
  overflow-x: hidden;
}
.carousel-indicators li {
  text-decoration: none;
  background-color: darkgray;
}

h1{
  font-size: 2.8rem !important;
  color: rgb(139, 120, 9) !important;
  font-weight: 900;
  font-family:Verdana, Geneva, Tahoma, sans-serif ;
}

b{
  font-weight: bolder;
}

h2{
  font-size: 2.3rem !important;
  color: rgb(139, 120, 9) !important;
  font-family:Verdana, Geneva, Tahoma, sans-serif ;
}

h3{
  font-size: 2.0rem !important  ;
  color: rgb(139, 120, 9) !important;
  font-family:Verdana, Geneva, Tahoma, sans-serif ;
}

h4{
  font-size: 2.2rem ;
  color: rgb(139, 120, 9);
  font-weight: bolder;
  font-family:Verdana, Geneva, Tahoma, sans-serif ;
}

h5{
  font-size: 2.0rem  ;
  color: rgb(139, 120, 9);
  font-family:Verdana, Geneva, Tahoma, sans-serif ;
}

h6{
  font-size: 1.5rem ;
  font-family:Verdana, Geneva, Tahoma, sans-serif ;
}

p{
  font-size: 16px;
  font-family: sans-serif;
}

@media(max-width:768px){

  h1{
    font-size: 2.5rem !important;
  }
  
  h2{
    font-size: 2rem !important;
  }

  h3{
    font-size: 1.8rem !important;
  }
  
  p{
    font-size: 15px;
  }
}