@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

a{
  text-decoration: none;
  color: rgb(139,120,9) !important;
}

a:hover{
  color:rgb(139,120,9);
}
.wrapper{
  padding: 5px 10px;
}

.heading {
  text-align: center;
  font-family: "Roboto" serif;
  font-size: 2rem;
  z-index: 2;
  margin: 40px 50px;
  color:rgb(139,120,9) !important;
}

.content {
  text-align: center;
  color:#000;
  line-height: 1.5rem;
  font-weight: normal;
  font-size: 1rem;
}

.img-background{
  width:100%;
  background-color: rgba(250,250,250,0.8);
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-background .imageContent{
  height: 150px;
  width:150px;
  border-radius: 50%;
  margin-top: 50px;
}

@media(max-width:768px) {
  .imageContent{
    height: 150px;
    width: 150px;
    font-size: 1rem;
    /* margin-bottom: 12rem; */
  }

  .content{
    line-height:1rem;
    font-size: 14px;
    margin-bottom: 30px;
  }

  .wrapper{
    padding: 0 10px;
  }
}